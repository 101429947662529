import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
// import {useMediaQuery} from "react-responsive";

import Main from './components/Main';
import Advertise from './components/Advertise';
import NotFound from './components/NotFound';
import Community from './components/Community';
// import Developers from './components/Developers';
// import ViralX from './components/ViralX';
// import Influencers from './components/Influencers';
// import Referral from './components/Referral';

// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import {aosSettings} from 'aosSettings';

import './App.scss';

const App = () => {
    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    // const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    // let isDesktopView = !isMdScreen;

    appHeightHandle();

    window.addEventListener('resize', () => {
        appHeightHandle();
    });

    // if (isDesktopView) {
    //     AOS.init(aosSettings);
    // }

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Main}/>
                    <Route exact path="/community" component={Community}/>
                    <Route exact path="/promotion" component={Advertise}/>
                    {/*<Route exact path="/developers" component={Developers}/>*/}
                    {/*<Route exact path="/viralx" component={ViralX}/>*/}
                    {/*<Route exact path="/influencers" component={Influencers}/>*/}
                    {/*<Route exact path="/earn" component={Referral}/>*/}
                    <Route component={NotFound}/>
                </Switch>
            </Router>
        </>
    )
};

export default App;
