import React from 'react';
import {Container, Row} from "react-bootstrap";

import heroBg from 'assets/img/developers/developers-hero-bg.png';
import heroImg from 'assets/img/developers/developers-hero-img.png';
import heroCardIcon from 'assets/img/general/card-angle-icon.svg';
import telegramIcon from "assets/img/general/telegram-icon.svg";

const Hero = () => {
    return (
        <section id="advertise-hero" className="advertise-hero-section">
            <div className="bg-wrap">
                <img src={heroBg} alt="Bg"/>
            </div>
            <Container>
                <Row>
                    <div className="left-wrap">
                        <div className="headline-wrap">
                            <h1>Get high value visibility</h1>
                        </div>
                        <div className="text-wrap">
                            <p>I am mainly an incubator, but overtime I have built up a nice network and get good
                                projects sent to me and good teams reaching out to me for marketing support daily. Since
                                I want to keep my main channel fully focussed on incubations only, I couldn't really
                                help these teams/projects, even though some of them ended up doing insane X's.<br/><br/>
                                After much consideration I've decided to open up a second channel, named the "gamble
                                calls" channel. This channel will focus on giving marketing support to projects that are
                                NOT incubated by me. It will be a mix between projects I am personally invested
                                in/confident in and projects that pay for promotion.</p>
                        </div>
                    </div>
                    <div className="right-wrap">
                        <div className="items-wrap">
                            <div className="item-small">
                                <div className="headline-wrap">
                                    <h4>Promote your<br/> project today</h4>
                                    <img src={telegramIcon} alt="Telegram Icon"/>
                                </div>
                                <div className="text-wrap">
                                    <p>My partner is in charge of project intake, for more info, pricing & details reach
                                        out to him through the button below.<br/><br/>
                                        *There's a 10% referral bonus if you refer a project to promote in my gambles
                                        channel!</p>
                                </div>
                                <div className="link-wrap">
                                    <a href="https://t.me/WalidKaawach" target="_blank" rel="noreferrer"
                                       className="btn-main">Refer a project</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="img-wrap">
                        <img src={heroImg} alt="Character"/>
                    </div>
                </Row>
            </Container>
        </section>
    )
}

export default Hero;
