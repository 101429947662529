import React from 'react';
import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from 'components/General/ScrollToTopOnMount';
import Header from 'components/General/Header';
import Footer from 'components/General/Footer';

import Hero from './Hero';
import Get from './Get';
import Require from './Require';
import Reviews from './Reviews';
import Projects from './Projects';
import Telegram from './Telegram';

const Main = () => {
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isSmScreen={isSmScreen}/>
            <main>
                <Hero isMdScreen={isMdScreen} isSmScreen={isSmScreen}/>
                <Get isMdScreen={isMdScreen}/>
                <Require isMdScreen={isMdScreen}/>
                <Reviews isMdScreen={isMdScreen}/>
                <Projects isMdScreen={isMdScreen}/>
                <Telegram isMdScreen={isMdScreen}/>
            </main>
            <Footer/>
        </>
    )
}

export default Main;
