import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

const Specifics = () => (
    <section id="advertise-specifics" className="advertise-specifics-section">
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="title-wrap">
                        <h2>Specifics</h2>
                    </div>
                    <div className="items-wrap">
                        <div className="item item-1">
                            <div className="headline-wrap">
                                <h4>This is who you reach</h4>
                            </div>
                            <div className="text-wrap">
                                <ol>
                                    <li>Tier 1/Tier 2 influencers</li>
                                    <li>High networth individuals</li>
                                    <li>Smart money traders</li>
                                    <li>Alpha group owners</li>
                                    <li>Platform builders/owners</li>
                                </ol>
                            </div>
                        </div>
                        <div className="item item-2">
                            <div className="headline-wrap">
                                <h4>These are the requirements</h4>
                            </div>
                            <div className="text-wrap">
                                <ol>
                                    <li>Renounced CA</li>
                                    <li>Locked LP</li>
                                    <li>Clean CA</li>
                                    <li>No outrageous amounts of unlocked tokens in team's hands</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
)

export default Specifics;
