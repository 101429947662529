import React, {useRef, useEffect, useState, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";

import lightBg from 'assets/img/main/main-light-bg.png';
import telegramIcon from 'assets/img/general/telegram-icon.svg';
import xIcon from 'assets/img/general/x-icon.svg';

const Require = ({isMdScreen}) => {
    const [isFixedHeadline, setIsFixedHeadline] = useState(false);
    const investorsRequireSectionRef = useRef();

    const {pathname} = useLocation();
    const isCommunityPage = useMemo(() => pathname === '/community', [pathname]);

    useEffect(() => {
        if (isCommunityPage && investorsRequireSectionRef) {
            window.addEventListener('scroll', () => {
                let blockTopPosition = investorsRequireSectionRef.current?.offsetTop || 0;

                setIsFixedHeadline(window.scrollY > Math.round(blockTopPosition));
            });

        }
    }, [isCommunityPage, investorsRequireSectionRef, setIsFixedHeadline]);

    return (
        <section id="community-require"
                 className={`community-require-section ${isFixedHeadline ? 'fixed-headline' : null}`}
                 ref={investorsRequireSectionRef}>
            <div className="light-wrap">
                <img src={lightBg} alt="Light"/>
            </div>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="title-wrap">
                            <h2>Disclaimer</h2>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="items-wrap">
                            <div className="item">
                                <h4>01</h4>
                                <p>I'm an incubator. I partner with promising projects pre-launch, identify their
                                    potential, help them with marketing, business and tokenomics advisory and vet them
                                    to make sure they are able to turn it all from an idea into a longterm succesful
                                    project.</p>
                            </div>
                            <div className="item">
                                <h4>02</h4>
                                <p>When a team checks all my boxes, I deploy, add liquidity and renounce their
                                    contracts. All calls that are made in here are deployed by me so I can guarantee the
                                    safety of the CA and the safety of the Liquidity Pool lock. In exchange for my
                                    advisory and marketing help I receive 1% of the taxes to the deployer wallet. The
                                    teams get their own marketing wallet in which they get the rest of the taxes.</p>
                            </div>
                            <div className="item">
                                <h4>03</h4>
                                <p>No matter how good my pre-launch due dilligence is, I do not own a crystal ball and
                                    can not predict what happens after a launch. I help teams wherever I can but in the
                                    end the teams are responsible for their longterm success. Some projects will do
                                    extremely well, some will perform incredibly dissappointing. I can not guarantee
                                    success, the only thing I can guarantee is teams having a product that - in my
                                    opinion - is viable and has potential.</p>
                            </div>
                            <div className="item">
                                <h4>04</h4>
                                <p>By interacting with anything posted by me you acknowledge that I am in NO WAY
                                    responsible for your actions. I share projects that I believe can do well longterm,
                                    that's where my responsibility stops and yours starts. You buy, you sell. Make
                                    money? Don't have to thank me, you did that. Lost money? Don't have to hate me, you
                                    did that.</p>
                            </div>
                            <div className="item">
                                <h4>Potential risk</h4>
                                <p>- Team can run off with the marketing wallet, I have no control over this<br/>
                                    - Team can dump the tokens that are meant to be used for marketing, I have no
                                    control over this<br/>
                                    - Team can give up or dissappear, I have no control over this.</p>
                            </div>
                            <div className="item">
                                <h4>Conclusion</h4>
                                <p>I try my best to guarantee safety, but there's a lot of bad actors in this space and
                                    I can't prevent highly advanced scams/hacks. I do as much as I can by making sure
                                    the contracts of the projects I call are safe, but as far as the actual utility
                                    goes, it's DYOR. I am no tech expert and in no condition to completely audit
                                    products/codebases to see if they have any malicious code. So stay safe, make sure
                                    you always connect to services you don't fully trust with a burner wallet, even if
                                    it's called by me.</p>
                            </div>
                            <div className="item-small">
                                <div className="headline-wrap">
                                    <h4>JOIN THE TELEGRAM</h4>
                                    <img src={telegramIcon} alt="Telegram Icon"/>
                                </div>
                                <div className="text-wrap">
                                    <a href="https://t.me/earlycallsby0xEly" target="_blank" rel="noreferrer"
                                       className="btn-main">Join Now</a>
                                </div>
                            </div>
                            <div className="item-small">
                                <div className="headline-wrap">
                                    <h4>FOLLOW ON X</h4>
                                    <img src={xIcon} alt="X Icon"/>
                                </div>
                                <div className="text-wrap">
                                    <a href="https://twitter.com/0xElyy" target="_blank" rel="noreferrer" className="btn-main">Follow</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Require;
