import {Container, Row, Col} from "react-bootstrap";
// import {Link} from 'react-router-dom';

import logo from 'assets/img/general/logo.svg';
import telegramIcon from 'assets/img/general/telegram-icon.svg';
import xIcon from 'assets/img/general/x-icon.svg';

const Footer = ({className}) => {
    return (
        <footer className={className}>
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <div className="logo-wrap">
                            <img src={logo} alt="Logo"/>
                        </div>
                    </Col>
                    {/*<Col xs={6} md={2} lg={3}>*/}
                    {/*    <div className="links-wrap">*/}
                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <Link to="/community">Community</Link>*/}
                    {/*            </li>*/}
                    {/*            <li>*/}
                    {/*                <Link to="/viralx">ViralX</Link>*/}
                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col xs={6} md={6} lg={6}>
                        <div className="links-wrap">
                            {/*<ul>*/}
                            {/*    <li>*/}
                            {/*        <Link to="/influencers">Influencers</Link>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <Link to="/referral">Referral Program</Link>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            <ul className="socials-wrap">
                                <li>
                                    <a href="https://twitter.com/0xElyy" target="_blank" rel="noreferrer">
                                        <img src={xIcon} alt="X"/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/earlycallsby0xEly" target="_blank" rel="noreferrer">
                                        <img src={telegramIcon} alt="Telegram"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="rights-wrap">
                            <p>© 2024 0xElly</p>
                            <p>All Rights Reserved</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
