import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import telegramCharacter from "assets/img/main/main-telegram-character.png";
import telegramCardIcon from 'assets/img/general/card-angle-icon.svg';

const Telegram = ({isMdScreen}) => {
    return (
        <section id="main-telegram" className="main-telegram-section">
            <div className="img-wrap">
                <img src={telegramCharacter} alt="Character"/>
            </div>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title-wrap">
                            <h2>GIVE YOUR PROJECT THE ATTENTION IT DESERVES</h2>
                        </div>
                        <a href="https://t.me/OxElyy" target="_blank" rel="noreferrer" className="card-wrap">
                            <div className="card-item">
                                <h4>Get in touch</h4>
                                <p>Send me a DM to explore the possibilities of launching your project through me.</p>
                                <img src={telegramCardIcon} alt="Icon"/>
                            </div>
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Telegram;
