import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {Container, Row, Col} from "react-bootstrap";

import lightBg from 'assets/img/main/main-light-bg.png';

const Require = ({isMdScreen}) => {
    const [isFixedHeadline, setIsFixedHeadline] = useState(false);
    const mainRequireSectionRef = useRef();

    const {pathname} = useLocation();
    const isMainPage = useMemo(() => pathname === '/', [pathname]);

    useEffect(() => {
        if (isMainPage && mainRequireSectionRef) {
            window.addEventListener('scroll', () => {
                let blockTopPosition = mainRequireSectionRef.current?.offsetTop || 0;
                let blockClientHeight = mainRequireSectionRef.current?.clientHeight || 0;
                setIsFixedHeadline(window.scrollY > Math.round(blockTopPosition));

                // console.log(window.scrollY);
                // console.log(Math.round(blockTopPosition));
                // console.log(blockClientHeight);
            });
        }
    }, [isMainPage, mainRequireSectionRef, setIsFixedHeadline]);

    return (
        <section id="main-require" className={`main-require-section ${isFixedHeadline ? 'fixed-headline' : null}`}
                 ref={mainRequireSectionRef}>
            <div className="light-wrap">
                <img src={lightBg} alt="Light"/>
            </div>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className="title-wrap">
                            <h2>What I require<br/> to work together</h2>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="items-wrap">
                            <div className="item">
                                <h4>Deploying and launching the CA</h4>
                                <p>Crypto is generally a toxic place full of scammers. To make sure my community and
                                    network do not get scammed I only work with teams that let me deploy and launch
                                    their tokens for them. This is the only way to ensure 100% safety for me, my
                                    community and network and therefore non-negotiable.<br/><br/>
                                    Obviously you will have full control over the deployer, I will perform any
                                    action you want me to. You will get your own tax wallet hardcoded in the CA to
                                    receive taxes. I will provide your liquidity for you and lock it for as long as
                                    the project is alive.</p>
                            </div>
                            <div className="item">
                                <h4>1% of the project's taxes</h4>
                                <p>Marketing usually costs a good chunk of ETH to be paid up-front. With you and
                                    your team already having to put in a lot of time and effort to get your utility
                                    developped, I don’t want you to spend even more without knowing if your project
                                    will be a success.<br/><br/>
                                    That’s why I take my payment in token taxes. No up-front marketing expenses for
                                    you, and the better I do, the more I earn and the more you subsequently earn
                                    aswell. I’d like to think of this as a win-win.<br/><br/>
                                    Note: there's a function built into the contract that allows me to revoke my
                                    share of the taxes that I can call even after renouncing. This becomes an option
                                    3 months after your launch, and will remove my 1% of taxes and send those to
                                    your marketing wallet aswell from that point onward, eliminating my compensation
                                    and ending our partnership.</p>
                            </div>
                            <div className="item">
                                <h4>Solid utility and longterm vision</h4>
                                <p>If I don’t have faith in your product, I won’t work with you. I don’t want to be
                                    another pump and dump caller. I do my best to deliver my community the best
                                    possible projects out there that won’t dissapear within a week, so I expect the
                                    teams I work with to have a longterm vision for their product. Obviously not
                                    everything survives, but going in with a shortterm mindset is a no go for
                                    me.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Require;
