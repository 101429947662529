import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import telegramCharacter from "assets/img/main/main-telegram-character.png";
import telegramIcon from "assets/img/general/telegram-icon.svg";

const Services = ({isMdScreen}) => {
    return (
        <section id="advertise-services" className="advertise-services-section">
            <div className="img-wrap">
                <img src={telegramCharacter} alt="Character"/>
            </div>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title-wrap">
                            <h2>make your project visible to new users today!</h2>
                        </div>
                        <div className="item-wrap">
                            <div className="item-small">
                                <div className="headline-wrap">
                                    <h4>Promote your<br/> project today</h4>
                                    <img src={telegramIcon} alt="Telegram Icon"/>
                                </div>
                                <div className="text-wrap">
                                    <p>My partner is in charge of project intake, for more info, pricing & details reach
                                        out to him through the button below.<br/><br/>
                                        *There's a 10% referral bonus if you refer a project to promote in my gambles
                                        channel!</p>
                                </div>
                                <div className="link-wrap">
                                    <a href="https://t.me/WalidKaawach" target="_blank" rel="noreferrer"
                                       className="btn-main">Get in touch</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Services;
