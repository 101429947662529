import {useEffect, useMemo, useState} from 'react';
import {useLocation, Link} from 'react-router-dom';
import {Navbar} from 'react-bootstrap';

import logo from 'assets/img/general/logo.svg';
import telegramIcon from 'assets/img/general/telegram-icon.svg';
import xIcon from 'assets/img/general/x-icon.svg';

const Header = ({isSmScreen}) => {
    const [scroll, setScroll] = useState(false);
    const {pathname} = useLocation();
    const isMainPage = useMemo(() => pathname === '/', [pathname]);
    const body = document.querySelector('body');

    let isDesktopView = !isSmScreen;

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 20);
        });
    }, [setScroll]);

    useEffect(() => {
        if (isMainPage && isDesktopView) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
    }, [isMainPage, isDesktopView, body])

    const handleTogglerClick = () => {
        document.querySelector('.navbar').classList.toggle('collapsed');
        document.querySelector('body').classList.toggle('navbar-opened');
    }

    const handleNavbarClose = () => {
        if (window.innerWidth <= 991) {
            document.querySelector('header .navbar .navbar-toggler').click();
            document.querySelector('body').classList.remove('navbar-opened');
        }
    }

    return (
        <header className={`${scroll ? 'scroll' : ''}`}>
            <Navbar expand="lg">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="Logo"/>
                </Link>
                <Navbar.Toggle onClick={handleTogglerClick} aria-controls="navbar-nav-dropdown">
                    <div className="text-wrap">
                        <p>Menu</p>
                    </div>
                    <div className="icons-wrap">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </div>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbar-nav-dropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/community"
                                  className={`${pathname === '/community' ? 'nav-link active' : 'nav-link'}`}
                                  onClick={handleNavbarClose}>Community</Link>
                        </li>
                        <li className="nav-item">
                            <a href="/promotion"
                               className={`${pathname === '/promotion' ? 'nav-link active' : 'nav-link'}`}
                               onClick={handleNavbarClose}>Promotion</a>
                        </li>
                    </ul>
                    <div className="navbar-actions">
                        <div className="navbar-socials">
                            <a href="https://t.me/earlycallsby0xEly" target="_blank" rel="noreferrer">
                                <img src={telegramIcon} alt="Telegram"/>
                            </a>
                            <a href="https://twitter.com/0xElyy" target="_blank" rel="noreferrer">
                                <img src={xIcon} alt="X"/>
                            </a>
                        </div>
                        <div className="navbar-cta">
                            <a href="https://t.me/elyprivateportal" target="_blank" rel="noreferrer"
                               className="btn-main">Private Community</a>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}

export default Header;
