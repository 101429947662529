import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel} from 'swiper';
import {Link, useLocation} from "react-router-dom";

import heroBg from 'assets/img/main/main-hero-bg.png';
import lightBg from 'assets/img/main/main-light-bg.png';
import heroImg from 'assets/img/main/main-hero-img.png';
import heroParticleOne from 'assets/img/main/main-hero-particles-1.png';
import heroParticleTwo from 'assets/img/main/main-hero-particles-2.png';
import heroCardIcon from 'assets/img/general/card-arrow-icon.svg';

const Hero = ({isMdScreen, isSmScreen}) => {
    const [isUpdated, setIsUpdated] = useState(false);
    const [heroSwiper, setHeroSwiper] = useState(null);
    const {pathname} = useLocation();
    const isMainPage = useMemo(() => pathname === '/', [pathname]);

    let isDesktopView = !isSmScreen;
    const body = document.querySelector('body');
    const pageLoading = document.querySelector('.page-loading-section');
    const pageLoadingProgress = document.querySelector('.page-loading-progress');

    if (isMainPage) {
        window.onresize = () => {
            isDesktopView = !isSmScreen;
        };
    }

    let isAutoScroll = false;

    const progressScroll = useCallback(() => {
        if (isMainPage) {
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            let scrolled = (winScroll / height) * 60 + 10;
            let highlighter = document.querySelector('header .progress-wrap .highlighter');

            if (highlighter) {
                highlighter.style.top = `calc(${scrolled}vh)`;
            }
        }
    }, [isMainPage])

    useEffect(() => {
        if (isDesktopView && isMainPage) {
            let progressWidth = 1;

            const progress = () => {
                if (progressWidth >= 100) {
                    clearInterval(id);
                } else {
                    progressWidth++;

                    if (pageLoadingProgress) {
                        pageLoadingProgress.style.width = progressWidth + "%";
                    }
                }
            }

            let id = setInterval(progress, 10);

            setTimeout(() => {
                window.scrollTo(0, 0);

                if (pageLoading) {
                    pageLoading.classList.add('hidden');
                }
            }, 500);
        }
    }, [isDesktopView, isMainPage, pageLoadingProgress, pageLoading, progressScroll])

    useEffect(() => {
        if (isMainPage) {
            window.addEventListener('scroll', () => {
                let activeIndex = heroSwiper?.activeIndex || 0;

                if (!isAutoScroll && window.scrollY === 0 && activeIndex === 1) {
                    body.classList.add('overflow-hidden');
                    heroSwiper.enable();
                    heroSwiper.slideTo(0, 1000);
                    setIsUpdated(false);
                }

                progressScroll();
            })
        }
    }, [isMainPage, isAutoScroll, body, heroSwiper, progressScroll])

    const handleSlideChange = (swiper) => {
        let {activeIndex} = swiper;

        if (activeIndex === 1) {
            swiper.disable();
            swiper.update();
            setIsUpdated(true);
            isAutoScroll = true;
            window.scrollTo(0, Math.round(window.innerHeight / 1000));
            setTimeout(() => {
                body.classList.remove('overflow-hidden');
                isAutoScroll = false;
            }, 1000);
        }
    };

    const handleCardClick = () => {
        heroSwiper.slideTo(1);
        // const mainGetSection = document.getElementById('main-get');
        //
        // if (mainGetSection) {
        //     const sectionTop = mainGetSection.offsetTop - 100;
        //
        //     if (window.innerWidth > 767) {
        //         setTimeout(() => {
        //             window.scrollTo({
        //                 top: sectionTop,
        //                 behavior: "smooth"
        //             });
        //         }, 1000);
        //     } else {
        //         window.scrollTo({
        //             top: sectionTop,
        //             behavior: "smooth"
        //         });
        //     }
        // }
    }

    return (
        <section id="main-hero" className={`main-hero-section ${isUpdated ? 'active' : ''}`}>
            <div className="bg-wrap">
                <img src={heroBg} alt="Bg"/>
            </div>
            <div className="light-wrap">
                <img src={lightBg} alt="Light"/>
            </div>
            <Swiper
                modules={[Mousewheel]}
                className="hero-swiper"
                direction={'vertical'}
                speed={1000}
                allowTouchMove={false}
                enabled={true}
                mousewheel={{releaseOnEdges: true}}
                breakpoints={{
                    0: {
                        enabled: false
                    },
                    768: {
                        enabled: true
                    },
                }}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
                onSwiper={(swiper) => setHeroSwiper(swiper)}
            >
                <SwiperSlide>
                    <div className="screen-1-wrap">
                        <div className="headline-wrap">
                            <h1>EXPERIENCED WEB3 INCUBATOR AND LAUNCHPAD</h1>
                        </div>
                        <div className="cards-wrap">
                            <Link to="/promotion" className="card-item">
                                <h5>My project is already live</h5>
                                <img src={heroCardIcon} alt="Icon"/>
                            </Link>
                            <div onClick={() => handleCardClick()} className="card-item">
                                <h5>have yet to launch my project</h5>
                                <img src={heroCardIcon} alt="Icon"/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="screen-2-wrap">
                        <div className="headline-wrap">
                            <h2>The oxely formula</h2>
                            <p>[Let the Numbers Talk]</p>
                        </div>
                        <div className="text-wrap">
                            <div className="numbers-wrap">
                                <div className="item">
                                    <h3>37</h3>
                                    <p>Projects<br/> Launched</p>
                                </div>
                                <div className="item">
                                    <h3>5,3m</h3>
                                    <p>Average<br/> ATH</p>
                                </div>
                                <div className="item">
                                    <h3>6,2m</h3>
                                    <p>Average<br/> Volume</p>
                                </div>
                            </div>
                            <div className="description-wrap">
                                <p>I help you achieve your project's full potential by combining my business
                                    experience
                                    with my marketing experience.</p>
                                <p>Pre-launch I advise wherever I can, ranging from the value proposition to your
                                    potential users and investors to tokenomics, go-to market strategy and marketing
                                    strategy.</p>
                                <p>Upon launch I make sure my community and network know all about your project and
                                    why
                                    they should be interested in it, often resulting in a lot of interest from
                                    investors
                                    and influencers that can help getting even more attention to your project.</p>
                                <p>After launch I remain onboard in an advisory position to help you navigate the
                                    web3
                                    world. Getting you in touch with the right people, helping you overcome
                                    obstacles
                                    and helping you with tough decisions.</p>
                            </div>
                            <div className="cta-wrap">
                                <a href="https://t.me/OxElyy" target="_blank" rel="noreferrer"
                                   className="btn-main">Get in Touch</a>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="img-wrap">
                <img src={heroImg} alt="Character"/>
            </div>
            <div className="particle-1-wrap">
                <img src={heroParticleOne} alt="Particle 1"/>
            </div>
            <div className="particle-2-wrap">
                <img src={heroParticleTwo} alt="Particle 2"/>
            </div>
        </section>
        // <section id="main-hero" className={`main-hero-section ${isUpdated ? 'active' : ''}`}>
        //     <div className="bg-wrap">
        //         <img src={heroBg} alt="Bg"/>
        //     </div>
        //     <div className="light-wrap">
        //         <img src={lightBg} alt="Light"/>
        //     </div>
        //     <Container>
        //         <Row>
        //             <div className="particle-1-wrap">
        //                 <img src={heroParticleOne} alt="Particle 1"/>
        //             </div>
        //             <div className="particle-2-wrap">
        //                 <img src={heroParticleTwo} alt="Particle 2"/>
        //             </div>
        //             <div className="screen-1-wrap">
        //                 <div className="headline-wrap">
        //                     <h1>Experienced web3 incubator and advisor</h1>
        //                 </div>
        //                 <div className="cards-wrap">
        //                     <Link to="/viralx" className="card-item">
        //                         <h5>My project is already live</h5>
        //                         <img src={heroCardIcon} alt="Icon"/>
        //                     </Link>
        //                     <a href="/" className="card-item">
        //                         <h5>have yet to launch my project</h5>
        //                         <img src={heroCardIcon} alt="Icon"/>
        //                     </a>
        //                 </div>
        //             </div>
        //             <div className="img-wrap">
        //                 <img src={heroImg} alt="Character"/>
        //             </div>
        //             <div className="screen-2-wrap">
        //                 <div className="headline-wrap">
        //                     <h2>The oxely formula</h2>
        //                     <p>[What's The Secret Sauce]</p>
        //                 </div>
        //                 <div className="text-wrap">
        //                     <div className="small-text-wrap">
        //                         <p>[Let the Numbers Talk]</p>
        //                     </div>
        //                     <div className="numbers-wrap">
        //                         <div className="item">
        //                             <h3>28</h3>
        //                             <p>Projects<br/> Launched</p>
        //                         </div>
        //                         <div className="item">
        //                             <h3>2,5m</h3>
        //                             <p>Average<br/> ATH</p>
        //                         </div>
        //                         <div className="item">
        //                             <h3>4m</h3>
        //                             <p>Average<br/> Volume</p>
        //                         </div>
        //                     </div>
        //                     <div className="description-wrap">
        //                         <p>I help you achieve your project's full potential by combining my business experience
        //                             with my marketing experience.</p>
        //                         <p>Pre-launch I advise wherever I can, ranging from the value proposition to your
        //                             potential users and community to tokenomics, go-to market strategy and marketing
        //                             strategy.</p>
        //                         <p>Upon launch I make sure my community and network know all about your project and why
        //                             they should be interested in it, often resulting in a lot of interest from community
        //                             and influencers that can help getting even more attention to your project.</p>
        //                         <p>After launch I remain onboard in an advisory position to help you navigate the web3
        //                             world. Getting you in touch with the right people, helping you overcome obstacles
        //                             and helping you with tough decisions.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </Row>
        //     </Container>
        // </section>
    )
}

export default Hero;
