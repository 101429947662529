import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import heroBg from 'assets/img/main/main-hero-bg.png';
import lightBg from 'assets/img/main/main-light-bg.png';
import heroImg from 'assets/img/main/main-telegram-character.png';
import telegramIcon from "assets/img/general/telegram-icon.svg";

const Hero = ({isMdScreen, isSmScreen}) => {
    return (
        <ParallaxProvider>
            <section id="community-hero" className="community-hero-section">
                <div className="bg-wrap">
                    <img src={heroBg} alt="Bg"/>
                </div>
                <div className="light-wrap">
                    <img src={lightBg} alt="Light"/>
                </div>
                <Parallax speed={isMdScreen ? -5 : -10} className="parallax img-wrap">
                    <img src={heroImg} alt="Character"/>
                </Parallax>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="text-wrap">
                                <h2>Why would you want to be a part of my community</h2>
                                <p>Get valuable information on promising, upcoming incubated launches and running
                                    projects by joining my Telegram Channel and following me on X. Want to take it a
                                    step further? Become part of the 0xEly community to find and benefit from all the
                                    opportunities this space has to offer!</p>
                            </div>
                            <div className="item-wrap">
                                <div className="item-small">
                                    <div className="headline-wrap">
                                        <h4>Join private community</h4>
                                        <img src={telegramIcon} alt="Telegram Icon"/>
                                    </div>
                                    <div className="link-wrap">
                                        <a href="https://t.me/elyprivateportal" target="_blank" rel="noreferrer"
                                           className="btn-main">Join Now</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Hero;
