import React from 'react';
import {useMediaQuery} from 'react-responsive';

import ScrollToTopOnMount from 'components/General/ScrollToTopOnMount';
import Header from 'components/General/Header';
import Footer from 'components/General/Footer';

import Hero from './Hero';
import Require from './Require';
// import Future from './Future';

const Community = () => {
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 767px)'});

    return (
        <>
            <ScrollToTopOnMount/>
            <Header isSmScreen={isSmScreen}/>
            <main>
                <Hero isMdScreen={isMdScreen} isSmScreen={isSmScreen}/>
                <Require isMdScreen={isMdScreen} isSmScreen={isSmScreen}/>
                {/*<Future isMdScreen={isMdScreen} isSmScreen={isSmScreen}/>*/}
            </main>
            <Footer/>
        </>
    )
}

export default Community;
