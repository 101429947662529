import {useEffect, useState} from "react";

const ScrollToTopOnMount = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
        setTimeout(() => setIsLoading(false), 1000);
    }, [isLoading]);

    if (isLoading) {
        return (
            <>
                <section className="preloader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </section>
            </>
        )
    }

    return null;
}

export default ScrollToTopOnMount;
