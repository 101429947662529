import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

import projectBlockLogo from 'assets/img/main/main-projects-1.png';
import projectTypeAILogo from 'assets/img/main/main-projects-2.png';
import projectBazedLogo from 'assets/img/main/main-projects-3.png';
import projectLiquiLogo from 'assets/img/main/main-projects-4.png';
import projectProduceAILogo from 'assets/img/main/main-projects-5.png';
import lightBg from "assets/img/main/main-light-bg.png";

const projectsData = [
    {
        id: 0,
        isHeadline: true,
        name: 'Project Name',
        x: 'X\'s From Call',
        called: 'Called At',
        high: 'All Time High',
        total: 'Total Volume',
    },
    {
        id: 1,
        isHeadline: false,
        imgUrl: projectTypeAILogo,
        name: 'TypeAI',
        x: '1025x',
        called: '40.000',
        high: '41.000.000',
        total: '32.000.000',
        link: 'https://t.me/earlycallsby0xEly/214'
    },
    {
        id: 2,
        isHeadline: false,
        imgUrl: projectBazedLogo,
        name: 'Bazed Games',
        x: '2857x',
        called: '14.000',
        high: '40.000.000',
        total: '46.400.000',
        link: 'https://t.me/earlycallsby0xEly/86'
    },
    {
        id: 3,
        isHeadline: false,
        imgUrl: projectProduceAILogo,
        name: 'ProduceAI',
        x: '450x',
        called: '40.000',
        high: '18.000.000',
        total: '16.000.000',
        link: 'https://t.me/earlycallsby0xEly/228'
    },
    {
        id: 4,
        isHeadline: false,
        imgUrl: projectLiquiLogo,
        name: 'Anima Spirit Gem',
        x: '850x',
        called: '13.000',
        high: '11.000.000',
        total: '13.000.000',
        link: 'https://t.me/earlycallsby0xEly/141'
    },
    {
        id: 5,
        isHeadline: false,
        imgUrl: projectBlockLogo,
        name: 'BlockRemit',
        x: '1312x',
        called: '8.000',
        high: '10.500.000',
        total: '15.000.000',
        link: 'https://t.me/earlycallsby0xEly/45'
    },
]

const Projects = ({isMdScreen}) => {

    return (
        <section id="main-projects" className="main-projects-section">
            <div className="light-wrap">
                <img src={lightBg} alt="Light"/>
            </div>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title-wrap">
                            <h2>Hall of fame</h2>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="items-wrap">
                            {projectsData.map((project) => {
                                const {id, isHeadline, imgUrl, name, x, called, high, total, link} = project;

                                return (
                                    <div className={`${isHeadline ? 'item item-headline' : 'item'}`} key={id}>
                                        <div className="item-col item-col-img">
                                            {imgUrl ? (
                                                <img src={imgUrl} alt="Project Logo"/>
                                            ) : ''}
                                        </div>
                                        <div className="item-col item-col-name">
                                            {isMdScreen ? <h5>Project Name</h5> : null}
                                            <h5>{name}</h5>
                                        </div>
                                        <div className="item-col">
                                            {isMdScreen ? <h5>X's From Call</h5> : null}
                                            <h5>{x}</h5>
                                        </div>
                                        <div className="item-col">
                                            {isMdScreen ? <h5>Called At</h5> : null}
                                            <h5>{called}</h5>
                                        </div>
                                        <div className="item-col">
                                            {isMdScreen ? <h5>All Time High</h5> : null}
                                            <h5>{high}</h5>
                                        </div>
                                        <div className="item-col">
                                            {isMdScreen ? <h5>Total Volume</h5> : null}
                                            <h5>{total}</h5>
                                        </div>
                                        <div className="item-col item-col-btn">
                                            {link ? (
                                                <a href={link} target="_blank" rel="noreferrer" className="btn-main">Link</a>
                                            ) : (
                                                <h5>Link to Call</h5>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Projects;
