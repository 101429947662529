import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

import lightBg from 'assets/img/main/main-light-bg.png';

const Get = ({isMdScreen}) => {

    return (
        <section id="main-get" className="main-get-section">
            <div className="light-wrap">
                <img src={lightBg} alt="Light"/>
            </div>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title-wrap">
                            <h2>what you<br/> get</h2>
                        </div>
                        <div className="items-wrap">
                            <div className="item item-1">
                                <div className="headline-wrap">
                                    <h4>01</h4>
                                    <h4>pre-launch</h4>
                                </div>
                                <div className="text-wrap">
                                    <ol>
                                        <li>Business development advice</li>
                                        <li>Tokenomics advice</li>
                                        <li>Token usecase advice</li>
                                        <li>Go-to-market strategies</li>
                                        <li>Marketing strategies and best practices</li>
                                        <li>Setting up connections to people that add value</li>
                                        <li>Announcement of the launch happening to my community & network</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="item item-2">
                                <div className="headline-wrap">
                                    <h4>02</h4>
                                    <h4>on-launch</h4>
                                </div>
                                <div className="text-wrap">
                                    <ol>
                                        <li>Fairlaunch based on best practices with minimal sniper impact</li>
                                        <li>Safe contract with all the functions you want, audited for maximum safety
                                        </li>
                                        <li>Liquidity provided and locked as long as project is active</li>
                                        <li>Kickstart your project with a sizeable treasury through balanced taxes</li>
                                        <li>Network of partners and alpha groups</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="item item-3">
                                <div className="headline-wrap">
                                    <h4>03</h4>
                                    <h4>After launch</h4>
                                </div>
                                <div className="text-wrap">
                                    <ol>
                                        <li>Continued support/advice throughout the projects lifespan</li>
                                        <li>Opinion on who's good to work with and who isn't</li>
                                        <li>Marketing strategies and best practices</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        // <section id="main-help" className="main-help-section">
        //     <div className="light-wrap">
        //         <img src={lightBg} alt="Light"/>
        //     </div>
        //     <Container>
        //         <Row>
        //             <Col xs={12}>
        //                 <div className="title-wrap">
        //                     <h2>Who сan<br/> I help?</h2>
        //                 </div>
        //                 <div className="items-wrap">
        //                     <div className="item item-1">
        //                         <div className="headline-wrap">
        //                             <h4>01</h4>
        //                             <h4>Community</h4>
        //                         </div>
        //                         <div className="text-wrap">
        //                             <p>The majority of my launches are stealth launches, often ending up in sub 10k mcap
        //                                 calls for high quality utility projects delivered by one of the many teams I
        //                                 work with. Join the channel and let’s find the next 1000x together!</p>
        //                             <a href="/" className="btn-main">Learn More</a>
        //                         </div>
        //                     </div>
        //                     <div className="item item-2">
        //                         <div className="headline-wrap">
        //                             <h4>02</h4>
        //                             <h4>Developers</h4>
        //                         </div>
        //                         <div className="text-wrap">
        //                             <p>Are you an excellent developer but do you struggle to find the right audience for
        //                                 your project? I’m here to help. My role is both advisory as well as marketing
        //                                 related and I’ll make sure your project gets the visibility it needs through my
        //                                 community and network of influencers.</p>
        //                             <a href="/" className="btn-main">Learn More</a>
        //                         </div>
        //                     </div>
        //                     <div className="item item-3">
        //                         <div className="headline-wrap">
        //                             <h4>03</h4>
        //                             <h4>Influencers</h4>
        //                         </div>
        //                         <div className="text-wrap">
        //                             <p>From now on every project that launches through me will reserve x % of it’s
        //                                 supply for marketing purposes. The majority of this will be allocated to the
        //                                 people in my influencer channel. The higher your impact, the higher your %
        //                                 allocated will be. But also, if you aren’t able to have enough of an impact, you
        //                                 don’t get paid at all and removed from the group.</p>
        //                             <a href="/" className="btn-main">Learn More</a>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </Col>
        //         </Row>
        //     </Container>
        // </section>
    )
}

export default Get;
