import React from 'react';
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import {Container, Row, Col} from 'react-bootstrap';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

import lightBg from 'assets/img/main/main-light-bg.png';
import reviewsImg from 'assets/img/main/main-reviews-character.png';

const reviewsSwiperData = [
    {
        id: 0,
        username: 'Big Blox',
        info: '$blox',
        text: 'Launching with Ely was an exceptional experience. Ely demonstrates utmost professionalism and prioritizes community. He actively provides insightful marketing advice, ensuring maximum exposure for the project. His recommendations are exceptional on influencers that will greatly benefit the project in the long run. Ely dedicates enhancing projects in the most effective manner possible. Ely remains active on Telegram, addresses concerns and ensuring moderators maintain a consistent and high standard of community interaction. Ely is always active making sure everything is in schedule and in pace.',
    },
    {
        id: 1,
        username: 'Hitesh Jain',
        info: '$CEX',
        text: 'It\'s challenging to capture the extent of 0xEly\'s contributions to the community, let alone to us. The journey so far has been a rollercoaster, and having someone like Ely has been truly bolstering. The community would undoubtedly benefit from more individuals who, like him, work with such integrity and honesty.',
    },
    {
        id: 2,
        username: 'Leo',
        info: '$asg',
        text: 'Professional service & dedicated support & always on advisory. Removes a lot of the unnecessary complexity of token launching to let projects like us focus more on the fundamental work that would create long-term impacts.',
    },
    {
        id: 3,
        username: 'Matsta',
        info: '$champz',
        text: 'Partnering with 0xEly is like a one-stop shop for project founder launching a token. He and his team take care of the smart contract deployment, the launch sequence, the necessary network to get started and even the initial liquidity pool. All that for a fair share of the revenue with predefined exit criteria - it’s just the best deal a founder can get for a head start out of the gates. And eventually, when the exit criteria are met, the partnership concludes amicably, leaving the full revenue stream to the project founder.',
    },
]

const Reviews = ({isMdScreen}) => {

    return (
        <ParallaxProvider>
            <section id="main-reviews" className="main-reviews-section">
                <div className="light-wrap">
                    <img src={lightBg} alt="Light"/>
                </div>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="title-wrap">
                                <h2>Don't take my<br/> word for it</h2>
                                <div className="small-text-wrap">
                                    <p>[Reviews]</p>
                                </div>
                            </div>
                            <div className="img-wrap">
                                <Parallax speed={isMdScreen ? -3 : -6} className="parallax">
                                    <img src={reviewsImg} alt=""/>
                                </Parallax>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="swiper-wrap">
                                <Swiper
                                    className="main-reviews-swiper"
                                    slidesPerView={2}
                                    slidesPerGroup={2}
                                    navigation={true}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        0: {
                                            slidesPerGroup: 1,
                                            slidesPerView: 1,
                                            spaceBetween: 0,
                                        },
                                        1320: {
                                            slidesPerView: 2,
                                            slidesPerGroup: 2,
                                            spaceBetween: 400,
                                            centeredSlides: false
                                        }
                                    }}
                                >
                                    {reviewsSwiperData && reviewsSwiperData.map(({id, username, info, text}) => (
                                        <SwiperSlide key={id}>
                                            <div className="headline-wrap">
                                                <p className="quote">“</p>
                                                <div className="info-wrap">
                                                    <h4>{username}</h4>
                                                    <h5>{info}</h5>
                                                </div>
                                            </div>
                                            <div className="text-wrap">
                                                <p>{text}</p>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Reviews;
